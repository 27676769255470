import { sendRequest } from '@/helpers/request';
import {
  API_DOCUMENT_DOWNLOAD,
  API_DOCUMENTS,
  API_LOCATION_DOCUMENTS,
  API_USER_DOCUMENT_UPLOAD,
  API_USER_DOCUMENTS,
} from './api_pathes';

async function getUserDocuments() {
  try {
    return await sendRequest(API_USER_DOCUMENTS, null, 'GET');
  } catch (error) {
    console.error('[DOCUMENTS] Something went wrong with request to get user documents', error);
    return {};
  }
}

async function getDocuments() {
  try {
    return await sendRequest(API_DOCUMENTS, null, 'GET');
  } catch (error) {
    console.error('[DOCUMENTS] Something went wrong with request to get user documents', error);
    return {};
  }
}

async function getLocationDocuments() {
  try {
    return await sendRequest(API_LOCATION_DOCUMENTS, null, 'GET');
  } catch (error) {
    console.error('[DOCUMENTS] Something went wrong with request to get location documents', error);
    return {};
  }
}

async function uploadDocument({ file, uploadsId }) {
  try {
    return await sendRequest(`${API_USER_DOCUMENT_UPLOAD}?uploadsId=${uploadsId}`, {
      file,
    }, 'POST', false, true);
  } catch (error) {
    console.error('[DOCUMENTS] Something went wrong with request to upload user document', error);
    return {};
  }
}

async function downloadDocument(id) {
  try {
    return await sendRequest(`${API_DOCUMENT_DOWNLOAD}/${id}`, null, 'GET', true);
  } catch (error) {
    console.error('[DOCUMENTS] Something went wrong with request to download doc', error);
    return {};
  }
}

export default {
  getUserDocuments,
  getLocationDocuments,
  uploadDocument,
  downloadDocument,
  getDocuments,
};
