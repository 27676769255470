  <template>
    <div class="unit-card">
      <div v-if="isSellModalOpen" class="modal" @click="closeSellModal">
        <div class="modal-content" @click.stop>
          <h1 class="sellAssetText">
            {{t("sellAsset")}}
          </h1>
          <p class="sellAssetDescription">
            {{t("specifyCost")}}
            <router-link
              class=""
              to="/promo"
              target="_blank"
              style="color: #2b54e2; text-decoration: none;"
            >
              {{t("offerPage")}}
            </router-link>
            {{ t("emailText") }}
          </p>

          <form-field
            class="password-change-form__input-field"
            input-type="number"
            :label="t('price')"
            v-model="salePrice"
          />

          <h3 class="sellAssetProfit">{{ t("profitability") }}</h3>
          <p class="sellAssetProfitPercent">{{ sellAssetProfit }} %</p>
          <div class="sellActions">
            <button class="cancelBtn" @click="closeSellModal">
              <img
                src="../../assets/cross-circle.svg"
                alt="cancel"
              >
              {{t("cancel")}}
            </button>
            <button class="sendBtn" @click="sellUnit">
              <img
                src="../../assets/check-circle.svg"
                alt="send"
              >
              {{t("send")}}
            </button>
          </div>
        </div>
      </div>

      <div v-if="isSellSuccessModalOpen" class="modal" @click="closeSellSuccessModal">
        <div class="modal-content" @click.stop>
          <h1 class="sellAssetText">
            {{t("soldAsset")}}
          </h1>
          <p class="sellAssetDescription">
            {{t("theAdIsLocated")}}
            <router-link
              class=""
              to="/promo"
              target="_blank"
              style="color: #2b54e2; text-decoration: none;"
            >
              {{t("offerPageDelete")}}
            </router-link>
            {{t("wantToCancle")}}
          </p>

          <div class="sellActions">
            <button class="sendBtn" @click="closeSellSuccessModal">
              <img
                src="../../assets/check-circle.svg"
                alt="ok"
              >
              {{t("ok")}}
            </button>
          </div>
        </div>
      </div>

      <div v-if="isRemoveSellModalOpen" class="modal" @click="closeRemoveSaleModal">
        <div class="modal-content" @click.stop>
          <h1 class="sellAssetText">
            {{t("areYouSure")}}
          </h1>
          <p class="sellAssetDescription">
           {{t("yourAd")}}
            <router-link
            class=""
            to="/promo"
            target="_blank"
            style="color: #2b54e2; text-decoration: none;"
          >
           {{t("offerPageDelete")}}
          </router-link>
           {{t("wantToList")}}
          </p>

          <div class="sellActions">
            <button class="cancelBtn" @click="closeRemoveSaleModal">
              <img
                src="../../assets/cross-circle.svg"
                alt="cancel"
              >
              {{t("cancel")}}
            </button>
            <button class="sendBtn" @click="removeSellUnit">
              <img
                src="../../assets/check-circle.svg"
                alt="confirm"
              >
              {{t("confirm")}}
            </button>
          </div>
        </div>
      </div>

      <div v-if="isRemoveSellSuccessModalOpen" class="modal" @click="closeRemoveSellSuccessModal">
        <div class="modal-content" @click.stop>
          <h1 class="sellAssetText">
            {{t("done")}}
          </h1>
          <p class="sellAssetDescription">
           {{t("removedFromSale")}}
          </p>

          <div class="sellActions">
            <button class="sendBtn" @click="closeRemoveSellSuccessModal">
              <img
                src="../../assets/check-circle.svg"
                alt="ok"
              >
              {{t("ok")}}
            </button>
          </div>
        </div>
      </div>

      <div class="unit-card__row">

        <div class="unit-card__carousel-wrapper">
          <Carousel v-if="imgArray.length > 1" :settings="carouselSettings">
            <template #slides>
              <Slide
                v-for="slide in imgArray"
                :key="slide.id"
              >
                <img
                  :src="slide.src"
                  alt=""
                />
                <div v-if="!isSale" class="unit-card-sell-text">
                  {{ t("listedForSale") }}
                </div>
              </Slide>
            </template>
            <template #addons>
              <Navigation />
              <Pagination />
            </template>
          </Carousel>

          <div class="one-img carousel__slide" v-else>
            <img
              :src="imgArray.length > 0 ? imgArray[0].src : ''"
              alt=""
            />
          </div>
        </div>

        <div class="unit-card__upper-block unit-card__upper-block--only-tablet">
          <div class="unit-card__name-block">
            <div class="unit-card__name-inner-block">
              <p class="unit-card__pre-title">{{ unitType }}</p>
              <title-main
                class="unit-card__title"
                :text="unitName"
              />
            </div>
          </div>
          <div class="unit-card__capacity-block">
            <span class="unit-card__capacity-label">{{ t('unit') }}</span>
            <div class="unit-card__capacity-inner-wrapper">
              <div class="unit-card__capacity-inner-block">
                <img
                  class="unit-card__capacity-icon"
                  src="@/assets/guestIcon.svg"
                  alt=""
                >
                <p class="unit-card__capacity-text-area">{{ guestCount }}</p>
              </div>
              <div class="unit-card__capacity-inner-block">
                <img
                  class="unit-card__capacity-icon"
                  src="@/assets/areaIcon.svg"
                  alt=""
                >
                <p class="unit-card__capacity-text-area">{{ formattedArea }}</p>
              </div>
            </div>
          </div>
         <div class="actions-mobile-none">
           <div style="height: 50px; margin-top: 20px;">
             <h1 style="display: inline; padding: 0;" v-if="isSale && averageIncome > 0"
                 @click="openSellModal" class="more-link more-link-border">{{ t("sellAsset") }}</h1>
             <h1 style="display: inline; padding: 0;"
                 v-else-if="!isSale && averageIncome > 0"
                 @click="openRemoveSaleModal"
                 class="more-link more-link-border">{{t("removeFromAsset")}}</h1>
           </div>
         <div>
           <more-link
             style="margin-top: 5px"
             class="unit-card__info-link more-link-border"
             :label="t('more')"
             :path="unitLink"
           />
         </div>
         </div>
        </div>
      </div>
      <div class="unit-card__info-wrapper">
        <div class="unit-card__upper-block">
          <div class="unit-card__name-block">
            <div class="unit-card__name-inner-block">
              <p class="unit-card__pre-title">{{ unitType }}</p>
              <title-main
                class="unit-card__title"
                :text="unitName"
              />
            </div>

            <div class="desktop-mobile-actions" >
              <h1 v-if="isSale && averageIncome > 0"
                  @click="openSellModal" class="more-link more-link-none more-link-border">
                {{ t("sellAsset") }}
              </h1>
              <h1 v-else-if="!isSale && averageIncome > 0"
                  @click="openRemoveSaleModal" class="more-link more-link-none more-link-border">
                {{t("removeFromAsset")}}
              </h1>

              <more-link
                class="unit-card__info-link unit-card__info-link--desktop
                 more-link-border more-link-none"
                :label="t('more')"
                :path="unitLink"
              />
            </div>
          </div>

          <div class="unit-card__capacity-block">
            <span class="unit-card__capacity-label">{{ t('unit') }}</span>
            <div class="unit-card__capacity-inner-wrapper">
              <div class="unit-card__capacity-inner-block">
                <img
                  class="unit-card__capacity-icon"
                  src="@/assets/guestIcon.svg"
                  alt="img"
                >
                <p class="unit-card__capacity-text-area">{{ guestCount }}</p>
              </div>
              <div class="unit-card__capacity-inner-block">
                <img
                  class="unit-card__capacity-icon"
                  src="@/assets/areaIcon.svg"
                  alt=""
                >
                <p class="unit-card__capacity-text-area">{{ formattedArea }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="unit-card__lower-block">
          <unit-income
            class="unit-card__income-block"
            :headerText="t('investment')"
            :priceText="formattedSum"
          />
          <unit-income
            class="unit-card__income-block"
            :headerText="t('dateInvestment')"
            :priceText="formattedDate"
          />
          <div class="unit-card__unit-state-block">
            <div class="unit-card__unit-state-inner-block">
              <p class="unit-card__unit-state-label">{{ t('stage') }}</p>
              <p class="unit-card__unit-state-info">{{ currentStatus }}</p>
            </div>
            <div
              v-if="false"
              class="unit-card__unit-state-inner-block"
            >
              <p class="unit-card__unit-state-label">{{ t('launch') }}</p>
              <p class="unit-card__unit-state-info">{{ willBeReady }}</p>
            </div>
          </div>
        </div>
        <div class="mobile-actions">
          <h1 style="width: 120px; padding: 0;text-align: center"
              v-if="isSale && averageIncome > 0" @click="openSellModal"
              class="more-link unit-card__info-link--mobile more-link-border">
            {{ t("sellAsset") }}
          </h1>
          <h1 style="width: 120px; padding: 0; text-align: center"
              v-else-if="!isSale && averageIncome > 0"
              @click="openRemoveSaleModal"
              class="more-link unit-card__info-link--mobile more-link-border">
            {{t("removeFromAsset")}}
          </h1>
          <more-link
            style="width: 150px; margin-top: 10px; text-align: center;"
            class="unit-card__info-link unit-card__info-link--mobile more-link-border"
            :label="t('more')"
            :path="unitLink"
          />
        </div>

      </div>
    </div>
  </template>

<script>
import TitleMain from '@/ui-kit/TitleMain/TitleMain.vue';
import MoreLink from '@/ui-kit/MoreLink/MoreLink.vue';
import UnitIncome from '@/components/UnitIncome/UnitIncome.vue';
import { updateMoneySum } from '@/helpers/common';

import 'vue3-carousel/dist/carousel.css';
import {
  Carousel, Slide, Pagination, Navigation,
} from 'vue3-carousel';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import FormField from '@/ui-kit/FormField/FormField.vue';
import userModel from '@/models/user';
import { useStore } from 'vuex';
import translate from './translate';

export default {
  name: 'UnitCard',
  components: {
    FormField,
    TitleMain,
    MoreLink,
    UnitIncome,
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  props: {
    unitType: {
      type: String,
      default: '',
    },
    isSale: {
      type: Boolean,
      default: false,
    },
    unitId: {
      type: Number,
      default: 0,
    },
    averageIncome: {
      type: Number,
      default: 0,
    },
    unitName: {
      type: String,
      default: '',
    },
    unitLink: {
      type: String,
      default: '',
    },
    guestCount: {
      type: String,
      default: '',
    },
    areaCount: {
      type: String,
      default: '',
    },
    investedSum: {
      type: String,
      default: '',
    },
    dateOfInvest: {
      type: String,
      default: '',
    },
    currentStatus: {
      type: String,
      default: '',
    },
    willBeReady: {
      type: String,
      default: '',
    },
    imgArray: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const { t, mergeLocaleMessage } = useI18n();
    const store = useStore();
    mergeLocaleMessage('en', translate.en);
    mergeLocaleMessage('ru', translate.ru);
    const carouselSettings = {
      itemsToShow: 1,
      wrapAround: true,
      snapAlign: 'center',
    };
    const formattedDate = computed(() => new Date(props.dateOfInvest)
      .toLocaleString().split(',')[0]);
    const formattedSum = computed(() => props.investedSum
      ? `${updateMoneySum(props.investedSum)}\u202F₽` : '');
    const formattedArea = computed(() => props.areaCount
      ? `${props.areaCount} м2` : '');

    const isSellModalOpen = ref(false);
    const isSellSuccessModalOpen = ref(false);
    const isRemoveSellModalOpen = ref(false);
    const isRemoveSellSuccessModalOpen = ref(false);
    const salePrice = ref('0');

    const sellAssetProfit = computed(() => (
      +salePrice.value
        ? +((((props.averageIncome * 12) / salePrice.value) * 100).toFixed(2))
        : 0
    ));

    const openSellModal = () => {
      isSellModalOpen.value = true;
    };

    const closeSellModal = () => {
      isSellModalOpen.value = false;
    };

    const openSellSuccessModal = () => {
      isSellSuccessModalOpen.value = true;
    };

    const closeSellSuccessModal = () => {
      isSellSuccessModalOpen.value = false;
    };

    const openRemoveSaleModal = () => {
      isRemoveSellModalOpen.value = true;
    };

    const closeRemoveSaleModal = () => {
      isRemoveSellModalOpen.value = false;
    };

    const openRemoveSellSuccessModal = () => {
      isRemoveSellSuccessModalOpen.value = true;
    };

    const closeRemoveSellSuccessModal = () => {
      isRemoveSellSuccessModalOpen.value = false;
    };

    const sellUnit = async () => {
      // await request for sell
      // closeSellModal();
      // openSellSuccessModal();

      if (salePrice?.value <= 0) {
        return;
      }
      const resp = await userModel.saleAnAsset(
        {
          price: salePrice?.value,
          unit_id: props?.unitId,
          // profit: sellAssetProfit?.value,
        },
      );
      if (resp) {
        await store.dispatch('user/getUserUnitsFullInfo');
        closeSellModal();
        openSellSuccessModal();
      }
    };

    const removeSellUnit = async () => {
      // await request remove for sell
      // closeRemoveSaleModal();
      // openRemoveSellSuccessModal();
      const resp = await userModel.removeFromSale({ unit_id: props?.unitId });
      if (resp) {
        await store.dispatch('user/getUserUnitsFullInfo');
        closeRemoveSaleModal();
        openRemoveSellSuccessModal();
      }
    };

    return {
      t,
      carouselSettings,
      formattedDate,
      formattedSum,
      formattedArea,
      isSellModalOpen,
      openSellModal,
      closeSellModal,
      isSellSuccessModalOpen,
      sellUnit,
      closeSellSuccessModal,
      isRemoveSellModalOpen,
      openRemoveSaleModal,
      closeRemoveSaleModal,
      isRemoveSellSuccessModalOpen,
      removeSellUnit,
      closeRemoveSellSuccessModal,
      salePrice,
      sellAssetProfit,
    };
  },
};
</script>

<style lang="scss">

.unit-card-sell-text {
  position: absolute;
  left: 11px;
  top: 11px;
  padding: 4px 10px 4px 10px;
  gap: 4px;
  border-radius: 12px;
  background: #2B54E2;
  color: #FFFFFF;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
}
.carousel {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  --vc-pgn-width: 6px;
  --vc-pgn-height: 6px;
  --vc-pgn-margin: 4px;
  --vc-pgn-border-radius: 45%;
  --vc-pgn-background-color: rgba(255,255,255,0.4);
  --vc-pgn-active-color: rgba(93, 195, 151, 0.4);
}

.carousel__next, .carousel__prev {
  display: none;
  font-size:20px;
  background: $white;
  border-radius: 100px;
  color: $grey900;
  width: 40px;
  height: 40px;
  transition: 0.2s;
  &:hover {
    color: $blue500;
    background: $blue100;
  }
}

.carousel__viewport {
  width: 100%;
  height: 100%;
  position: relative;
}

.carousel__pagination {
  display: none;
  margin-bottom: 10px;
  padding: 0;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.carousel__slide {
  img {
    width: 100%;
    object-fit: cover;
  }
}

.carousel__track {
  height: 100%;
}
.more-link-border {
  padding: 12px 16px 12px 16px !important;
  gap: 4px;
  border-radius: 4px 0 0 0 !important;
  border: 1px solid #EFF2F6 !important;
  margin-left: 5px !important;
  margin-top: 5px;
}

.carousel__slide img {
  min-height: 100%;
}
.modal {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
}
.one-img img {
  position: relative;
  min-height: 100%;
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1.2;
}
.one-img img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -13%);
  min-height: 100%;
  min-width: 100%;
  object-fit: cover;
  aspect-ratio: 1.2;
}

.desktop-mobile-actions {
  display: flex;
}
@media (max-width: 600px) {
  .more-link-none {
    display: none !important;
  }
  .more-link-border {
    display: inline-block !important;
  }
  .desktop-mobile-actions {
    display: block;
  }
}

.modal-content {
  position: relative;
  background-color: #fff;
  margin: 10% auto;
  padding: 24px 16px;
  width: 90%;
  max-width: 435px;
  border-radius: 8px;
}

.close {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 24px;
  cursor: pointer;
}
.sellActions {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}

.cancelBtn {
  border-radius: 4px;
  border: 1px solid #DBDCDE;
  color: #89909E;
  background: #FFFFFF;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  padding: 2px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
}
.sendBtn {
  border-radius: 4px;
  border: 1px solid #DBDCDE;
  color: #2BBE9B;
  background: #FFFFFF;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  padding: 2px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: auto;
}

.sellAssetText {
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
  margin-top: 0;
  color: #141F3C;
}
.sellAssetDescription {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #6A7383;

}
.sellAssetProfit {
  margin-top: 22px;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  color: #6A7383;
}
.sellAssetProfitPercent {
  margin-top: 0;
  font-size: 32px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #141F3C;
}

.unit-card {
  display: flex;
  position: relative;

  &__row {
    display: flex;
    width: fit-content;
  }

  &__carousel-wrapper {
    width: 389px;
    height: 242px;
    border-radius: 12px;
    border: 1px solid $grey300;
    position: relative;
    overflow: hidden;
    isolation: isolate;
    transition: 0.2s;
    cursor: pointer;
    &:hover .carousel__prev, &:hover .carousel__next, &:hover .carousel__pagination{
      display: flex;
    }
  }

  &__info-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 0 0 0 25px;
  }

  &__upper-block {
    display: flex;
    flex-direction: column;

    &--only-tablet {
      display: none;
    }
  }

  &__name-block {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
  }

  &__name-inner-block {
    display: flex;
    flex-direction: column;
  }

  &__pre-title {
    margin: 0;
    padding: 0;
    font-size: $font-size-tiny;
    font-weight: $font-weight-half-bold;
    line-height: 16px;
    color: $main-black;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }

  &__title {
    margin: 7px 0 0 0;
    color: $main-black;
  }

  &__info-link {
    &--mobile {
      display: none;
    }
  }

  &__capacity {
    &-block {
      display: flex;
      margin: 44px 0 0 0;
    }

    &-label {
      display: none;
    }

    &-inner {
      &-wrapper {
        display: flex;
      }
      &-block {
        display: flex;
        align-items: center;

        &:last-child {
          margin: 0 0 0 18px;
        }
      }
    }

    &-icon {
      width: 12px;
      height: 12px;
    }

    &-text {
      &-area {
        margin: 0 0 0 5px;
        padding: 0;
        font-size: $font-size-regular;
        font-weight: $font-weight-medium;
        line-height: 18px;
        color: $grey800;
      }
    }
  }

  &__lower-block {
    display: flex;
    margin: 11px 0 0 0;
    border-top: 1px solid $grey200;
    border-bottom: 1px solid $grey200;
  }

  &__income-block {
    padding: 16px 0 23px 12px;
    min-width: 248px;
    width: 33.33%;
    border-right: 1px solid $grey200;
    flex-grow: 1;
    flex-shrink: 0;

    &:first-child {
      padding-left: 0;
    }
  }

  &__unit-state-block {
    display: flex;
    width: 33.33%;
    flex-direction: column;
    padding: 16px 0 23px 12px;
  }

  &__unit-state-inner-block {
    display: flex;

    &:nth-child(2) {
      margin: 8px 0 0 0;
    }
  }

  &__unit-state-label {
    margin: 0;
    padding: 0;
    font-size: $font-size-regular;
    font-weight: $font-weight-medium;
    line-height: 18px;
    color: $main-black;
    width: 51px;
  }

  &__unit-state-info {
    margin: 0 0 0 18px;
    padding: 0;
    font-size: $font-size-regular;
    font-weight: $font-weight-medium;
    line-height: 18px;
    color: $grey800;
  }
}

@media screen and (max-width: $tablet-breakpoint) {
  .unit-card {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__carousel-wrapper {
      width: 50%;
      height: 221px;
    }

    &__row {
      width: 100%;
    }

    &__upper-block {
      display: none;

      &--only-tablet {
        display: flex;
        width: 50%;
        padding-left: 22px;
      }
    }

    &__capacity {
      &-block {
        margin: 27px 0 0 0;
      }

      &-inner {
        &-wrapper {
          display: flex;
        }
      }
    }

    &__info-link {
      margin-top: auto;
      padding: 0;
    }

    &__info-wrapper {
      margin-left: 0;
    }

    &__lower-block {
      margin: 16px 0 0 0;
    }

    &__income-block {
      min-width: initial;
    }
  }
}

@media screen and (max-width: 600px) {
  .actions-mobile-none {
    display: none !important;
  }
}
@media screen and (max-width: $mobile-breakpoint) {

  .mobile-actions {
    display: flex;
    flex-direction: column;
  }
  .desktop-mobile-actions {
    display: none;
  }
  .unit-card {
    padding: 0 16px;

    &__carousel-wrapper {
      width: 100%;
      height: 222px;
    }

    &__row {
      width: 100%;
    }

    &__info-wrapper {
      width: 100%;
      margin-left: 0;
      margin-top: 18px;
    }

    &__upper-block {
      display: flex;
      flex-direction: column-reverse;
      padding-bottom: 16px;

      &--only-tablet {
        display: none;
      }
    }

    &__name-inner-block {
      .unit-card__pre-title {
        display: none;
      }
    }

    &__capacity {
      &-block {
        margin-top: 0;
        justify-content: space-between;
      }

      &-label {
        display: block;
        font-weight: $font-weight-medium;
        font-size: $font-size-tiny;
        line-height: 16px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: $grey800;
      }

      &-text {
        &-area {
          font-size: $font-size-small;
        }
      }

      &-inner {
        &-block {
          &:last-child{
            margin: 0;
          }
        }

        &-wrapper {
          gap: 0 12px;
        }
      }
    }

    &__info-link {
      &--desktop {
        display: none;
      }

      &--mobile {
        display: block;
        padding-left: 0;
      }
    }

    &__lower-block {
      margin-top: 0;
      flex-direction: column;
      border-bottom: none;
    }

    &__income-block {
      width: 100%;
      border-right: none;
      border-bottom: 1px solid $grey200;
      padding: 25px 0 24px 0;

      .unit-income {
        &__header {
          .info-header {
            font-size: $font-size-small;
          }
        }

        &__info-wrapper {
          margin: 20px 0 0 0;
        }
      }
    }

    &__unit-state {
      &-block {
        width: 100%;
        padding: 24px 0;
      }

      &-label, &-info {
        font-size: $font-size-small;
      }
    }
  }
}
</style>
