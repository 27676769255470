<template>
  <div class="info-card">
    <div class="info-card__icons">
      <img src="../../assets/info.svg" alt="info" class="info-card__icon">
    </div>
    <div class="info-card__text-block">
      <span class="info-card__title">{{ title }}</span>
      <span class="info-card__subtitle">{{ subtitle }}</span>
    </div>
    <div
      v-if="url && url.length"
      class="info-card__links"
    >
      <more-link
        class="info-card__link"
        :label="linkText"
        :path="url"
      />
    </div>
  </div>
</template>

<script>
import MoreLink from '../MoreLink/MoreLink.vue';

export default {
  name: 'info-card',
  components: {
    MoreLink,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: '',
    },
    linkText: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss">
.info-card {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 14px 16px;
  line-height: 18px;
  border-radius: 4px;
  border: 1px solid $grey200;
  background: $grey100;
  font-size: $font-size-regular;

  &__text-block {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    max-width: 70%;
  }

  &__subtitle {
    color: $grey800;
  }

  &__links {
    margin-left: auto;
  }

  &__link {
    text-wrap: nowrap;
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .info-card {
    flex-direction: column;

    &__text-block {
      margin-left: 0;
      max-width: 100%;
    }

    &__subtitle {
      margin-top: 4px;
    }

    &__links {
      margin-left: -8px;
      margin-top: 16px;
    }
  }
}
</style>
