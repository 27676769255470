<template>
  <div v-if="leaveRequestModal" class="modal" @click="closeLeaveRequestModal">
    <div class="modal-content" @click.stop>
      <h1 class="sellAssetText">
        {{t("leaveRequest")}}
      </h1>
      <p class="sellAssetDescription">
     {{t("sendLeaveReqMessage")}}
      </p>

      <div class="sellActions">
        <button class="cancelBtn" @click="closeLeaveRequestModal" >
          <img
            src="../../assets/cross-circle.svg"
            alt="cancel"
          >
          {{t("cancel")}}
        </button>
        <button class="sendBtn" @click="leaveRequestSend"  >
          <img
            src="../../assets/check-circle.svg"
            alt="confirm"
          >
          {{t("send")}}
        </button>
      </div>
    </div>
  </div>

  <div v-if="sendRequestSuccess" class="modal" @click="closeLeaveRequestSuccessModal">
    <div class="modal-content" @click.stop>
      <h1 class="sellAssetText">
        {{t("done")}}
      </h1>
      <p class="sellAssetDescription">
       {{t("applicationHasSend")}}
      </p>

      <div class="sellActions">
        <button class="sendBtn" @click="closeLeaveRequestSuccessModal">
          <img
            src="../../assets/check-circle.svg"
            alt="ok"
          >
          {{t("ok")}}
        </button>
      </div>
    </div>
  </div>

  <div class="promo-unit-card">
    <div class="promo-unit-card__carousel-wrapper">
      <Carousel
        v-if="imageArray && imageArray.length"
        :settings="carouselSettings"
      >
        <template #slides>
          <Slide
            v-for="slide in imageArray"
            :key="slide"
          >
            <img
              :src="slide"
              alt=""
            >
<!--            <div v-if="showButton" class="my-unit">-->
<!--              {{t("myLot")}}-->
<!--            </div>-->
          </Slide>
        </template>
        <template
          #addons
          v-if="imageArray.length > 1"
        >
          <Navigation/>
          <Pagination/>
        </template>
      </Carousel>
      <UiLabel
        v-if="promo"
        class="promo-unit-card__ui-label"
        :label="t('greatOffer')"
        background=""
      />
    </div>
    <div class="promo-unit-card__info-wrapper">
      <div class="promo-unit-card__upper-block">
        <title-main
          class="promo-unit-card__title"
          :text="unitName"
        />
        <div class="promo-unit-card__capacity-block">
          <div class="promo-unit-card__capacity-inner-block">
            <img
              class="promo-unit-card__capacity-icon"
              src="@/assets/guestIcon.svg"
              alt=""
            >
            <p class="promo-unit-card__capacity-text-area">{{ guestCount }}</p>
          </div>
          <div class="promo-unit-card__capacity-inner-block">
            <img
              class="promo-unit-card__capacity-icon"
              src="@/assets/areaIcon.svg"
              alt=""
            >
            <p class="promo-unit-card__capacity-text-area">{{ formattedArea }}</p>
          </div>
        </div>
      </div>
      <div class="promo-unit-card__middle-block-one">
        <div class="promo-unit-card__string-block">
          <p class="promo-unit-card__inner-label">
            Локация
          </p>
          <UiLabel
            class="promo-unit-card__inner-ui-label"
            :label="labelsObject.location.text"
            :background="labelsObject.location.color"
          />
        </div>
<!--        <div class="promo-unit-card__string-block">-->
<!--          <p class="promo-unit-card__inner-label">-->
<!--            Тип актива-->
<!--          </p>-->
<!--          <UiLabel-->
<!--            class="promo-unit-card__inner-ui-label"-->
<!--            :label="labelsObject.unitType.text"-->
<!--            :background="labelsObject.unitType.color"-->
<!--          />-->
<!--        </div>-->
        <div class="promo-unit-card__string-block">
          <p class="promo-unit-card__inner-label">
            {{t("stage")}}
          </p>
          <UiLabel
            class="promo-unit-card__inner-ui-label"
            :label="labelsObject.unitState.text"
            :background="labelsObject.unitState.color"
          />
        </div>
      </div>
      <div class="promo-unit-card__underline"></div>
<!--      <div class="promo-unit-card__middle-block-two">-->
<!--        <div class="promo-unit-card__string-block-two">-->
<!--          <p class="promo-unit-card__inner-label">-->
<!--            Запуск-->
<!--          </p>-->
<!--          <p class="promo-unit-card__inner-info">-->
<!--            {{ launchDate }}-->
<!--          </p>-->
<!--        </div>-->
<!--        <div class="promo-unit-card__string-block-two">-->
<!--          <p class="promo-unit-card__inner-label">-->
<!--            Доходность-->
<!--          </p>-->
<!--          <p class="promo-unit-card__inner-info">-->
<!--            {{ profitability }} %-->
<!--          </p>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="promo-unit-card__underline"></div>-->
      <div class="unit-card-profit-price-container">
        <div class="promo-unit-card-price-profit">
          <div class="promo-unit-price-profit-text">
            {{t("priceLot")}}
          </div>
          <div class="promo-unit-price-profit-number">
            {{ formattedPrice }} ₽
          </div>
        </div>
        <div style=" border-left: 1px solid #eff2f6 ;padding-inline: 10px;"
             class="promo-unit-card-price-profit">
          <div class="promo-unit-price-profit-text">
            {{t("profitability")}}
          </div>
          <div class="promo-unit-price-profit-number">
            {{ profitability }} %
          </div>
        </div>
        <div></div>
      </div>
      <div class="">
        <div class="promo-unit-card__string-block-three">
<!--          <p class="promo-unit-card__inner-label">-->
<!--            Стоимость-->
<!--          </p>-->
          <div class="promo-unit-card__prices-block">
            <div class="promo-unit-card__discounts-block">
<!--              {{ defaultPrice }} ₽-->
<!--              <PopupWrapper-->
<!--                arrow-->
<!--                placement="auto"-->
<!--                class="promo-unit-card__popup"-->
<!--                hover-->
<!--              >-->
<!--                <div-->
<!--                  v-if="defaultPrice"-->
<!--                  class="promo-unit-card__discount-block"-->
<!--                >-->
<!--                  <p class="promo-unit-card__discount">-->
<!--                    {{ formattedDefaultPrice }}-->
<!--                  </p>-->
<!--                  <svg-->
<!--                    class="promo-unit-card__discount-icon"-->
<!--                    width="17"-->
<!--                    height="17"-->
<!--                    viewBox="0 0 17 17"-->
<!--                    fill="none"-->
<!--                    xmlns="http://www.w3.org/2000/svg"-->
<!--                  >-->
<!--                    <path-->
<!--                      d="M16.0687 0.931719C15.7907-->
<!--                      0.653312 15.4207 0.5 15.0271 0.5H9.41809C9.02462 0.5-->
<!--                      8.65469 0.65325 8.37644 0.931531L0.931594-->
<!--                      8.37638C0.65325 8.65469 0.5 9.02459-->
<!--                      0.5 9.41797C0.5 9.81141 0.653313-->
<!--                      10.1813 0.931594 10.4594L6.54047 16.0683C6.81866-->
<!--                      16.3467 7.18856 16.5 7.582 16.5C7.97541 16.5 8.34531-->
<!--                      16.3468 8.62362 16.0684L16.0685 8.62356C16.3468-->
<!--                      8.34531 16.5 7.97538 16.5 7.58191V1.97291C16.5-->
<!--                      1.57925 16.3467 1.20931 16.0687 0.931719ZM8.50003-->
<!--                      4.18678C9.31219 4.18678 9.97294 4.84753 9.97294-->
<!--                      5.65969C9.97294 6.47184 9.31219 7.13259 8.50003-->
<!--                      7.13259C7.68788 7.13259 7.02712 6.47184 7.02712-->
<!--                      5.65969C7.02712 4.84753 7.68788 4.18678 8.50003-->
<!--                      4.18678ZM8.50003 12.8132C7.68788 12.8132 7.02712-->
<!--                      12.1524 7.02712 11.3403C7.02712 10.5281 7.68788-->
<!--                      9.86734 8.50003 9.86734C9.31219 9.86734 9.97294-->
<!--                      10.5281 9.97294 11.3403C9.97294 12.1524 9.31219-->
<!--                      12.8132 8.50003 12.8132ZM11.5125 8.96872H5.48756C5.22869-->
<!--                      8.96872 5.01881 8.75884 5.01881 8.49997C5.01881-->
<!--                      8.24109 5.22869 8.03122 5.48756 8.03122H11.5125C11.7714-->
<!--                      8.03122 11.9812 8.24109 11.9812 8.49997C11.9812-->
<!--                      8.75884 11.7714 8.96872 11.5125 8.96872ZM13.5208-->
<!--                      3.94791C13.2621 3.94791 13.0521 3.73791 13.0521-->
<!--                      3.47916C13.0521 3.22041 13.2621 3.01041 13.5208-->
<!--                      3.01041C13.7796 3.01041 13.9896 3.22041 13.9896-->
<!--                      3.47916C13.9896 3.73791 13.7796 3.94791 13.5208 3.94791Z"-->
<!--                      fill="#6A7383"-->
<!--                    />-->
<!--                  </svg>-->
<!--                </div>-->
<!--              <template #popperContent>-->
<!--                  <p class="promo-unit-card__popup-content">-->
<!--                    Цена с учетом скидки 2% для действующих инвесторов VIEW GA-->
<!--                  </p>-->
<!--                </template>-->
<!--              </PopupWrapper>-->
              <div
                v-if="discountPrice"
                class="promo-unit-card__discount-block"
              >
                <p
                  class="promo-unit-card__discount
                  promo-unit-card__discount-state-green"
                >
                  {{ formattedDiscountPrice }}
                </p>
                <svg
                  class="promo-unit-card__discount-icon"
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.0688 0.43125C15.7906 0.153125 15.4219 0
                    15.0281 0H9.41875C9.025 0 8.65625 0.153125 8.375
                    0.43125L0.93125 7.875C0.653125 8.15625 0.5 8.525
                    0.5 8.91875C0.5 9.3125 0.653125 9.68125 0.93125
                    9.95938L6.54063 15.5688C6.81875 15.8469 7.1875
                    16 7.58125 16C7.975 16 8.34375 15.8469 8.62187
                    15.5688L16.0688 8.125C16.3469 7.84688 16.5 7.47813
                    16.5 7.08437V1.47187C16.5 1.07812 16.3469 0.709375
                    16.0688 0.43125ZM9.99375 9.55313L8.00313 11.8531C7.825
                    12.0594 7.50625 12.0594 7.33125 11.8531L5.34062
                    9.55313C4.79063 8.88125 4.79063 7.91563 5.34062
                    7.24062C5.78437 6.69062 6.59375 6.60313 7.14375
                    7.05C7.21563 7.10625 7.27812 7.17188 7.33437
                    7.24062C7.49062 7.42188 7.60312 7.63125 7.66875
                    7.85938C7.73125 7.62813 7.84688 7.4125 8.00313
                    7.22813C8.44688 6.67813 9.25625 6.59062 9.80625
                    7.0375C9.87813 7.09375 9.94063 7.15938 9.99687
                    7.22813C10.275 7.56563 10.4125 7.97813 10.4125
                    8.39062C10.4125 8.80313 10.2719 9.21563 9.99375
                    9.55313ZM13.5219 3.44688C13.2625 3.44688 13.0531
                    3.2375 13.0531 2.97813C13.0531 2.71875 13.2625
                    2.50938 13.5219 2.50938C13.7813 2.50938 13.9906
                    2.71875 13.9906 2.97813C13.9906 3.2375 13.7781
                    3.44688 13.5219 3.44688Z"
                    fill="#5DC397"
                  />
                </svg>
              </div>
            </div>
            <p class="promo-unit-card__final-price">
              {{ formattedInvestorPrice }}
            </p>
          </div>
        </div>
      </div>
<!--      <a-->
<!--        v-if="url && url.length"-->
<!--        :href="url"-->
<!--        class="promo-unit-card__link"-->
<!--      >-->
<!--        <primary-button-->
<!--          @click="onClick"-->
<!--          class="promo-unit-card__button"-->
<!--          fullWidth-->
<!--          label="Забронировать юнит"-->
<!--        />-->
<!--      </a>-->
      <primary-button
        v-if="!showButton"
        class="promo-unit-card__button"
        fullWidth
        :label="t('leaveRequest')"
        @click="openLeaveRequestModal"
      />
    </div>
  </div>
</template>

<script>
import TitleMain from '@/ui-kit/TitleMain/TitleMain.vue';
import UiLabel from '@/ui-kit/UiLabel/UiLabel.vue';
import PrimaryButton from '@/ui-kit/PrimaryButton/PrimaryButton.vue';
import { updateMoneySum } from '@/helpers/common';

import { computed, ref } from 'vue';

import 'vue3-carousel/dist/carousel.css';
import {
  Carousel,
  Slide,
  Pagination,
  Navigation,
} from 'vue3-carousel';
import { useI18n } from 'vue-i18n';
import userModel from '@/models/user';
import { useStore } from 'vuex';

export default {
  name: 'PromoUnitCard',
  components: {
    TitleMain,
    PrimaryButton,
    UiLabel,
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  props: {
    promo: {
      type: Boolean,
      default: false,
    },
    imageArray: {
      type: Array,
      default: () => [],
    },
    unitName: {
      type: String,
      default: '',
    },
    photoUrl: {
      type: String,
      default: '',
    },
    unitId: {
      type: Number,
      default: 0,
    },
    investorId: {
      type: Number,
      default: 0,
    },
    guestCount: {
      type: String,
      default: '',
    },
    areaCount: {
      type: String,
      default: '',
    },
    labelsObject: {
      type: Array,
      default: () => {},
    },
    launchDate: {
      type: String,
      default: '',
    },
    profitability: {
      type: String,
      default: '',
    },
    defaultPrice: {
      type: String,
      default: '',
    },
    discountPrice: {
      type: String,
      default: '',
    },
    investorPrice: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: '',
    },
  },
  computed: {
    formattedPrice() {
      return this.defaultPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    },
  },
  emits: ['on-click'],
  setup(props, { emit }) {
    const carouselSettings = {
      itemsToShow: 1,
      wrapAround: true,
      snapAlign: 'center',
    };

    const { t } = useI18n();
    const leaveRequestModal = ref(false);
    const sendRequestSuccess = ref(false);
    const store = useStore();
    const userState = store.state.user;

    const openLeaveRequestModal = () => {
      leaveRequestModal.value = true;
    };

    const showButton = props.investorId === userState.profileSettings.id;

    const closeLeaveRequestModal = () => {
      leaveRequestModal.value = false;
    };
    const closeLeaveRequestSuccessModal = () => {
      sendRequestSuccess.value = false;
    };

    const leaveRequestSend = async () => {
      leaveRequestModal.value = false;
      sendRequestSuccess.value = true;
      const resp = await userModel.sendRequestToBuy({
        // owner_investor_id: userState.profileSettings.id,
        owner_investor_id: props.investorId,
        unit_id: props.unitId,
      });
      console.log(resp);
      // leaveRequestModal.value = false;
      // sendRequestSuccess.value = true;
    };

    const formattedDefaultPrice = computed(() => props.defaultPrice
      ? `${updateMoneySum(props.defaultPrice)}\u202F₽` : '');
    const formattedDiscountPrice = computed(() => props.discountPrice
      ? `${updateMoneySum(props.discountPrice)}\u202F₽` : '');
    const formattedInvestorPrice = computed(() => props.investorPrice
      ? `${updateMoneySum(props.investorPrice)}\u202F₽` : '');
    const formattedArea = computed(() => props.areaCount
      ? `${props.areaCount} м\u00B2` : '');

    const onClick = () => {
      emit('on-click');
    };

    return {
      t,
      carouselSettings,
      formattedDefaultPrice,
      formattedDiscountPrice,
      formattedInvestorPrice,
      formattedArea,
      onClick,
      leaveRequestModal,
      closeLeaveRequestModal,
      openLeaveRequestModal,
      leaveRequestSend,
      closeLeaveRequestSuccessModal,
      sendRequestSuccess,
      showButton,
    };
  },
};
</script>

<style lang="scss">
@import "PromoUnitCard";

.promo-unit-card-price-profit {
  padding-block: 20px;
  width: 50%;
  border-top: 0.1px solid #EFF2F6;
  border-bottom: 0.1px solid #EFF2F6;
}
.promo-unit-price-profit-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #141F3C;

}

.my-unit {
  position: absolute;
  left: 15px;
  top: 15px;
  background: #2B54E2;
  padding: 4px 10px 4px 10px;
  gap: 4px;
  border-radius: 12px;
  color: white;

}
.unit-card-profit-price-container {
  display: flex;
  justify-content: center;
}
.promo-unit-price-profit-number {
  margin-top: 20px;
  font-size: 32px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #141F3C;
}

@media screen and (max-width: $tablet-breakpoint) {
  .promo-unit-price-profit-number {
    margin-top: 20px;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #141F3C;
  }
}
@media screen and (max-width: 650px) {
  .unit-card-profit-price-container {
    flex-direction: column;
  }
  .promo-unit-card-price-profit{
    border: none !important;
    padding: 5px 0 !important;
  }
  .promo-unit-price-profit-number {
    margin-top: 5px;
  }
  .promo-unit-card-price-profit {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px !important;
  }
  .promo-unit-price-profit-text {
    width: 120px;
  }
  @media screen and (max-width: 480px) {
    .promo-unit-card-price-profit {
      display: flex;
      width: 100%;
      align-items: center;
      padding-right: 0!important;
      justify-content: start;
    }
    .promo-unit-card__info-wrapper {
      padding-bottom: 40px;
    }
  }

}

</style>
