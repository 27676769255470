const messages = {
  en: {
    investor: 'investor',
    settings: 'Account settings',
    actives: 'My assets',
    documents: 'My documents',
    exit: 'Log out',
    news: 'News',
    faq: 'FAQ',
    promo: 'Promo',
    unit: 'unit | units | units',
    logIn: 'Log in',
    menuMain: 'Summary',
    menuIncome: 'Your income',
    menuTimeline: 'Project timeline',
    menuReports: 'Project reports',
    menuUnits: 'Room inventory',
    menuBooking: 'Reservations',
    menuIncomeOutcome: 'Income and expenses',
    menuPeriphery: 'Outbuildings',
    menuControls: 'Quality control',
    menuCameras: 'Cameras',
    menuChecks: 'Checks',
    menuInventory: 'Inventory',
    menuTooltipHeaderUnavailable: 'Section unavailable',
    menuTooltipTextUnavailable: 'You don\'t have any {msg}, so this section is not available to you.',
    weekAgo: 'A week ago',
    twoWeekAgo: 'Two week ago',
    monthAgo: 'A month ago',
    twoMonthAgo: 'Two months ago',
    inMonth: 'In a month',
    inTwoMonths: 'In two months',
    source: 'Source',
    today: 'Today',
    cancel: 'Cancel',
    send: 'Send',
    selectedDate: 'Selected date',
    change: 'Change',
    password: 'Password',
    changePassword: 'Change password',
    save: 'Save',
    notSpecified: 'Not specified',
    needSign: 'There\'s a document that needs to be signed',
    bookingSources: {
      reception: 'Reception',
      call: 'Phone call',
      sales: 'From the sales channel',
      web: 'Hotel\'s website',
      fax: 'Fax',
      email: 'Email',
      vk: 'VK',
      mobile: 'Mobile website',
      fb: 'Facebook',
      ta: 'TripAdvisor',
      extranet: 'Booking from an extranet',
      ya: 'Yandex',
      mobileExtranet: 'Mobile extranet',
    },
    lenderMenuTooltipHeaderUnavailabe: 'This section is only for users with an investment agreement',
    lenderMenuTooltipTextUnavailable: 'Contact sales to learn more.',
    requestForDocument: 'Request for document',
    requestForDocumentText: 'Fill out the application for receiving the document. '
      + 'We will process it and add the document to this page. If we have any questions '
      + 'while processing the request, we will contact you.',
    selectDocument: 'Select document',
    commentDocument: 'Leave a comment if needed',
    request: 'Request',
    sellAsset: 'Sell an asset',
    soldAsset: 'Done! Asset is sold',
    ok: 'Ok',
    removeFromAsset: 'Remove from sale',
    areYouSure: 'Are you sure?',
    confirm: 'Confirm',
    done: 'Done!',
    leaveRequest: 'Leave a request',
    sendLeaveReqMessage: 'We will forward your request to the seller and the '
      + 'inwis sales department, they will contact you to discuss the details '
      + 'of the transaction.',
    applicationHasSend: 'Your application has been sent.',
    doneRequestSend: 'Done! Your request has been sent',
    weWillUpload: 'We will upload the document to this page or contact you to clarify details using the contacts provided in your profile.',
    yourAd: 'Your ad will no longer be on',
    offerPage: 'offer pages.',
    offerPageDelete: 'proposal page.',
    wantToList: 'If you wish to list the unit for sale again, you can do so on this page.',
    theAdIsLocated: 'The ad is located on',
    wantToCancle: 'If you want to cancel it, you can do so on this page.',
    profitability: 'Profitability',
    removedFromSale: 'Your unit has been successfully removed from sale.',
    specifyCost: 'Specify the cost of the unit and we will create an ad for',
    emailText: 'When one of the investors is interested in your message,'
      + ' we will send a notification to the email specified in your account.',
    listedForSale: 'Listed for sale',
    price: 'Cost in ₽',
    promoThisPageYouCan: 'On this page you can see the lots of inwis investors'
      + ' and leave requests for the offers you like.',
    annual: 'Annual',
    average: 'average',
    profit: 'profitability',
    fromSigning: 'from signing the contract to receiving the first income',
    month: 'month',
    guaranteed: 'guaranteed',
    minimumIncome: 'minimum income',
    annualAverage: 'annual - average yield',
    annualGaranted: 'annual - guaranteed minimum income',
    signingContract: 'from signing the contract to receiving the first income',
    lots: 'Lots',
    viewUnit: 'View units',
    greatOffer: 'Great offer',
    stage: 'Stage',
    priceLot: 'Price',
    myLot: 'My lot',

  },
  ru: {
    investor: 'инвестор',
    settings: 'Настройки аккаунта',
    actives: 'Мои активы',
    documents: 'Мои документы',
    send: 'Отправить',
    exit: 'Выйти',
    news: 'Новости',
    faq: 'FAQ',
    unit: 'юнит | юниты | юнитов',
    logIn: 'Вход',
    menuMain: 'Главная',
    menuIncome: 'Ваши доходы',
    menuTimeline: 'Таймлайн проекта',
    menuReports: 'Отчеты проекта',
    menuUnits: 'Номерной фонд',
    menuBooking: 'Бронирования',
    menuIncomeOutcome: 'Доходы и расходы',
    menuPeriphery: 'Периферия',
    menuControls: 'Контроль качества',
    menuCameras: 'Камеры',
    menuChecks: 'Проверки',
    menuInventory: 'Инвентаризации',
    menuTooltipHeaderUnavailable: 'Раздел недоступен',
    menuTooltipTextUnavailable: 'У вас нет {msg}, поэтому этот раздел вам недоступен.',
    weekAgo: 'Неделя назад',
    promo: 'Предложения',
    twoWeekAgo: 'Две недели назад',
    monthAgo: 'Месяц назад',
    twoMonthAgo: 'Два месяца назад',
    inMonth: 'Через месяц',
    inTwoMonths: 'Через два месяца',
    source: 'Источник',
    today: 'Сегодня',
    selectedDate: 'Выбранное число | Выбранная дата',
    change: 'Изменить',
    changePassword: 'Изменить пароль',
    password: 'Пароль',
    cancel: 'Отменить',
    save: 'Сохранить',
    notSpecified: 'Не указано',
    needSign: 'Есть документ, который надо подписать',
    bookingSources: {
      reception: 'От стойки',
      call: 'По звонку',
      sales: 'Из канала продаж',
      web: 'Сайт гостиницы',
      fax: 'Факс',
      email: 'Email',
      vk: 'ВКонтакте',
      mobile: 'Мобильная версия сайта',
      fb: 'Facebook',
      ta: 'TripAdvisor',
      extranet: 'Бронирование из экстранета',
      ya: 'Yandex',
      mobileExtranet: 'Мобильный экстранет',
    },
    lenderMenuTooltipHeaderUnavailabe: 'Раздел только для пользователей с инвестиционным договором',
    lenderMenuTooltipTextUnavailable: 'Свяжитесь с отделом продаж, чтобы узнать больше.',
    requestForDocument: 'Запрос документа',
    requestForDocumentText: 'Заполните заявку на получение документа.'
      + ' Мы обработаем ее и добавим документ на эту страницу. '
      + 'Если в процессе обработки запроса у нас появятся вопросы —'
      + ' мы свяжемся с вами.',
    selectDocument: 'Выберите документ',
    commentDocument: 'Напишите комментарий, если нужно',
    request: 'Запросить',
    sellAsset: 'Продать актив',
    soldAsset: 'Готово! Лот выставлен',
    ok: 'Хорошо',
    removeFromAsset: 'Снять с продажи',
    areYouSure: 'Вы уверены?',
    confirm: 'Подтвердить',
    done: 'Готово!',
    leaveRequest: 'Оставить заявку',
    sendLeaveReqMessage: 'Мы отправим вашу заявку продавцу и отделу продаж inwis, они свяжутся с вами, чтобы обсудить детали сделки.',
    applicationHasSend: 'Ваша заявка отправлена.',
    doneRequestSend: 'Готово! Ваш запрос отправлен',
    weWillUpload: 'Мы&nbsp;загрузим документ на&nbsp;эту страницу или свяжемся с&nbsp;вами для уточнения деталей по&nbsp;контактам, указанным в&nbsp;профиле.',
    yourAd: 'Вашего объявления больше не будет на',
    offerPage: 'страницы предложений.',
    offerPageDelete: 'странице предложений.',
    wantToList: 'Если вы захотите снова выставить юнит на продажу, это можно сделать на этой странице.',
    theAdIsLocated: 'Объявление находится на',
    wantToCancle: 'Если вы захотите его отменить, это можно сделать на этой странице.',
    profitability: 'Доходность',
    specifyCost: 'Укажите стоимость юнита, и мы сформируем объявление для',
    emailText: 'Когда кто-то из инвесторов заинтересуется вашим сообщением,'
      + ' мы отправим оповещение на почту, указанную у вас в аккаунте.',
    removedFromSale: 'Ваш юнит успешно снят с продажи.',
    listedForSale: 'Выставлено на продажу',
    price: 'Стоимость в ₽',
    promoThisPageYouCan: 'На этой странице вы можете увидеть лоты инвесторов inwis'
      + '  и оставить заявки на понравившиеся вам предложения.',
    annual: 'годовых',
    average: 'средняя',
    profit: 'доходность',
    fromSigning: 'от подписания договора до получения первого дохода',
    month: 'мес',
    guaranteed: 'гарантированный',
    minimumIncome: 'минимальный доход',
    annualAverage: 'годовых — средняя доходность',
    signingContract: 'от подписания договора до получения первого дохода',
    annualGaranted: 'годовых — гарантированный минимальный доход',
    lots: 'Лоты',
    viewUnit: 'Посмотреть юниты',
    greatOffer: 'Выгодное предложение',
    stage: 'Стадия',
    priceLot: 'Стоимость',
    myLot: 'Мой лот',

  },
};

export default {
  legacy: false,
  globalInjection: true,
  fallbackLocale: 'en',
  messages,
};
