export default {
  en: {
    unit: 'Unit',
    more: 'More about income',
    investment: 'Your investment',
    dateInvestment: 'Дата инвестирования',
    stage: 'Stage',
    launch: 'Launch',
  },
  ru: {
    unit: 'Юнит',
    more: 'Подробнее о доходе',
    investment: 'Вы инвестировали',
    dateInvestment: 'Дата инвестирования',
    stage: 'Стадия',
    launch: 'Запуск',
  },
};
